import axios from '@/libs/axios';

export default {
    getSendMyCVHistory(id) {
        if (!id) throw new Error("Id cannot be null")
        return axios.get('/cv-messages/admin/sent-history/' + id)
    },

    getSentHistory(filter){
        return axios.post('/cv-messages/admin/sent-history', filter)
    }
}
