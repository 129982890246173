export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    title: "Users",
    icon: "UserIcon",
    children: [
      {
        title: "All",
        route: "users",
      },
      {
        title: "Reported",
        route: "reported-users",
      },
      {
        title: "Blocked",
        route: "blocked-users",
      },
      {
        title: "Affiliate",
        route: "affiliate-users",
      },
    ],
  },
  {
    title: "Posts",
    icon: "SmartphoneIcon",
    children: [
      {
        title: "All",
        route: "posts",
      },
      {
        title: "Promoted",
        route: "sponsored-posts",
      },
      {
        title: "Reported",
        route: "reported-posts",
      },
    ],
  },
  {
    title: "Jobs",
    icon: "FileIcon",
    children: [
      {
        title: "Pending",
        route: "jobs-pending",
      },
      {
        title: "Active",
        route: "jobs-active",
      },
      {
        title: "Draft",
        route: "jobs-draft",
      },
    ],
  },
  {
    title: "Blogs",
    icon: "FileIcon",
    route: "blogs",
  },
  {
    title: "News",
    icon: "FileIcon",
    route: "news",
  },
  {
    title: "Send my CV",
    icon: "SendIcon",
    children: [
      {
        title: "Sent CVs",
        route: "send-my-cv",
      },
      {
        title: "Recruiters",
        route: "recruiters",
      },
      {
        title: "External Recruiters",
        route: "external-recruiters",
      },
    ],
  },
];
