import { ref } from "@vue/composition-api";
import useHttpService from "@/http/useHttpService";

const { users } = useHttpService();

export default function useUserFilters(forRecruiters) {
  const statusOptions = [
    { label: "Any", value: null },
    { label: "Visible", value: true },
    { label: "Hidden", value: false },
  ];

  const typeOptions = [
    { label: "Any", value: null },
    { label: "Crew", value: "Crew" },
    { label: "Recruiter", value: "Recruiter" },
  ];

  const companyTypeOptions = [
    { label: "Recruiter", value: "RECRUITER" },
    { label: "Company", value: "COMPANY_REPRESENT" },
    { label: "Industry professional", value: "INDUSTRY_PROFESSIONAL" },
  ];

  const sendMyCvAllowedOptions = [
    { label: "Allowed", value: "true" },
    { label: "Not Allowed", value: "false" },
  ];

  const verifiedOptions = [
    { label: "All", value: null },
    { label: "Verified", value: false },
    { label: "Not verified", value: true },
  ];

  const industryOptions = ref([]);
  const countryOptions = ref([]);

  if (forRecruiters) {
    users.getUsersFilters().then((response) => {
      industryOptions.value = response.fieldOfWorks.filter((industry) => {
        return industry.uid !== "05a0cedc-61f1-4f0d-b01c-9fe48f8aead1";
      });
      countryOptions.value = response.countries;
    });
  } else {
    users.getUsersFilters().then((response) => {
      industryOptions.value = response.fieldOfWorks;
      countryOptions.value = response.countries;
    });
  }

  return {
    statusOptions,
    typeOptions,
    industryOptions,
    countryOptions,
    companyTypeOptions,
    verifiedOptions,
    sendMyCvAllowedOptions,
  };
}
