<template>
  <div>

    <job-list-filters :published-date.sync="jobPublishedFilter"
                      @onJobPositionChange="onJobPositionChange"
                      @onFieldOfWorkChanged="onFieldOfWorkChanged"
                      @onJobCategoryChange="onJobCategoryChange"
                      :viewsDate.sync="viewsRange"
                      :field-of-works="fieldOfWorks" v-if="!jobStatus"
    ></job-list-filters>

    <b-card no-body class="mb-0">

      <div class="m-2">
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>

            <b-button
                @click="exportJobs"
                class="ml-5"
                variant="success"
            >
              Export
            </b-button>

          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
          ref="refTable"
          class="position-relative"
          :items="fetchJobs"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(workPosition)="data">
          <b-link
              target="_blank"
              class="font-weight-bold d-block text-nowrap"
              :to="resolveJobLink(data.item)"
          >
            {{ data.item.workPosition }}
          </b-link>
        </template>

        <template #cell(status)="data">
          <b-badge
              :variant="`light-${resolveJobStatusColor(data.item.status)}`"
              class="text-capitalize font-weight-normal"
          >
            {{ data.item.status.toLowerCase() }}
          </b-badge>
        </template>

        <template #cell(created)="data">
          <div style="width: 80px">{{ data.item.created | mapDate }}</div>
        </template>

        <template #cell(actions)="data">
          <b-button
              v-if="jobStatus"
              size="sm"
              @click="verifyJob(data.item.uid)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-success"
          >
            <feather-icon
                icon="CheckIcon"
                class="mr-50"
            />
            <span class="align-middle">Approve</span>
          </b-button>
          <b-button @click="openDeleteJobDialog(data.item.uid, data.item.workPosition)" class="ml-2" variant="primary"
                    size="sm"
          >Delete
          </b-button>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{
                dataMeta.of
              }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalCount"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJobsList from '@/containers/jobs/useJobsList'
import JobListFilters from '@/containers/jobs/JobListFilters'

export default {
  props: {
    jobStatus: [String, null],
    default: null,
  },
  components: {
    JobListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    openDeleteJobDialog(uid, title) {
      this.$bvModal
          .msgBoxConfirm(`Please confirm that you want to delete job "${title}"`, {
            title: 'Job delete',
            centered: true,
            cancelVariant: 'outline-secondary',
          })
          .then(value => {
            if (value) {
              this.deleteJob(uid)
            }
          })
    }
  },
  setup({ jobStatus }) {
    return {
      ...useJobsList(jobStatus)
    }
  }
}
</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
</style>
