<template>
  <div>
    <h3 class="mb-2">Edit sponsored company</h3>
    <div>
      <label>Date</label>
      <flat-pickr
          v-model="date"
          class="form-control"
          :config="{ mode: 'range'}"
      />
    </div>

    <div class="d-flex justify-content-end mt-2 mb-1">
      <b-button
          v-if="date"
          variant="secondary"
          class="mr-1"
          @click="date = null"
      >
        Clear
      </b-button>
      <b-button @click="save" variant="primary">Save</b-button>
    </div>
  </div>
</template>

<script>
import {BFormInput, BFormGroup, BButton} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { toastMixin } from '@/mixins/toast-mixin'

export default {
  name: 'EditCompanyPremium',
  props: {
    uid: String,
    dateFrom: String || null,
    dateTo: String || null
  },
  mixins: [toastMixin],
  components: {
    BFormInput,
    BFormGroup,
    vSelect,
    flatPickr,
    BButton
  },
  data() {
    return {
      date: null
    }
  },
  methods: {
    async save() {
      try {
        let [fromDate, untilDate] = this.date !== null  ? this.date.split(' to ') : []
        if(!this.date) {
          fromDate = null
          untilDate = null
        }
        await this.$httpService.users.updateCompanyPremium(this.uid, fromDate, untilDate)
        this.$emit('created')
      } catch (e) {
        this.showToast('error', 'AlertTriangleIcon', 'Error', 'Something went wrong!')
      }
    }
  },
  created() {
    const dateFrom = this.dateFrom?.split('T')[0]
    const dateTo = this.dateTo?.split('T')[0]
    if(dateFrom && dateTo) {
      this.date = `${dateFrom} to ${dateTo}`
    }
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.checkbox {
  cursor: pointer;
}
</style>
