<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <dashboard-summary :crew="crew" :recruiters="recruiters"></dashboard-summary>
      </div>
      <div class="col-md-6">
        <dashboard-industry-stats :jobs="jobs" :industry-stats="industryStats"></dashboard-industry-stats>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardSummary from '@/components/dashboard/DashboardSummary'
import { ref } from '@vue/composition-api'
import useHttpService from '@/http/useHttpService'
import DashboardIndustryStats from '@/components/dashboard/DashboardIndustryStats'
const {stats} = useHttpService();


export default {
  components: { DashboardIndustryStats, DashboardSummary },
  setup() {
    const crew = ref(0)
    const jobs = ref(0)
    const recruiters = ref(0)
    const industryStats = ref([])

    stats.getStats().then(response => {
      const {newCrews, newJobs, newRecruiters, newJobsByFieldOfWorks} = response;

      crew.value = newCrews;
      jobs.value = newJobs;
      recruiters.value = newRecruiters;
      industryStats.value = newJobsByFieldOfWorks
    })

    return {
      crew,
      jobs,
      recruiters,
      industryStats
    }
  }
}
</script>

<style scoped>

</style>
