<template>
  <div>

    <b-card no-body>

      <b-card-header class="pb-50">
        <h5>Filters</h5>
      </b-card-header>

      <b-card-body>
        <div class="row">
          <div class="col-md-3">
            <label>Publish date</label>
            <flat-pickr
                v-model="dateRange"
                class="form-control"
                :config="{ mode: 'range'}"
            />
          </div>
          <div class="col-md-2">
            <label>Industry</label>
            <v-select
                @input="$emit('onFieldOfWorkChanged', $event)"
                :options="fieldOfWorks"
                v-model="industry"
                label="name"
                :reduce="val => val.uid"
            ></v-select>
          </div>
          <div class="col-md-2">
            <label>Category</label>
            <v-select
                @input="$emit('onJobCategoryChange', $event)"
                :options="categories"
                v-model="category"
                label="name"
                :reduce="val => val.uid"
            ></v-select>
          </div>
          <div class="col-md-2">
            <label>Position</label>
            <v-select
                @input="$emit('onJobPositionChange', $event)"
                :options="positions"
                label="name"
                :reduce="val => val.uid"
            ></v-select>
          </div>
          <div class="col-md-2">
            <label>Views date</label>
            <flat-pickr
                v-model="viewsRange"
                class="form-control"
                :config="{ mode: 'range'}"
            />
          </div>
        </div>
      </b-card-body>

    </b-card>

  </div>
</template>

<script>

import {
  BCard, BCardHeader, BCardBody
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    flatPickr,
    vSelect
  },
  props: {
    publishedDate: {
      type: [String, null],
      default: null
    },
    fieldOfWorks: Array,
    viewsDate: {
      default: null
    },
  },
  data() {
    return {
      dateRange: null,
      industry: null,
      category: null,
      position: null,
      viewsRange: null
    }
  },
  watch: {
    dateRange: {
      immediate: false,
      handler(value) {
        this.$emit('update:publishedDate', value)
      }
    },
    viewsRange: {
      immediate: false,
      handler(value) {
        this.$emit('update:viewsDate', value)
      }
    }
  },
  computed: {
    categories() {
      if(this.industry) {
        const group = this.fieldOfWorks.find(item => item.uid === this.industry)
        return group.workPositionGroups
      }
      return []
    },
    positions() {
      if(this.category) {
        const category = this.categories.find(item => item.uid === this.category)
        return category.workPositions
      }
      return []
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
