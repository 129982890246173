import { ref } from '@vue/composition-api'
import useHttpService from '@/http/useHttpService'

const {users} = useHttpService()

export default function usePostFilters() {
  const userRoles = [
    {label: 'Any', value: null},
    {label: 'Crew', value: 'Crew'},
    {label: 'Recruiter', value: 'Recruiter'},
  ]
  const industryOptions = ref([]);

  users.getUsersFilters()
    .then(response => {
      industryOptions.value = response.fieldOfWorks
    })

  return {
    userRoles,
    industryOptions
  }
}
