import axios from '@/libs/axios';
import {stringifyGetParams} from "@/helpers/api-params";

export default {
    getRecruiters(page = 1, pageSize = 10, search = null, industryIds = []) {
        const getParams = stringifyGetParams({page, pageSize, search, FieldOfWorkIds: industryIds})
        return axios.get('/external-recruiters?' + getParams)
    },

    getRecruiter(uid) {
        return axios.get('/external-recruiters/' + uid)
    },

    saveRecruiter(data) {
        return axios.post('/external-recruiters', data)
            .catch(function (error) {
                if(error && error.response && error.response.data)
                    return Promise.reject(error.response.data)
        })
    },

    deleteRecruiter(id) {
        return axios.delete('/external-recruiters/' + id)
    },

    importRecruiters(formData) {
        const headers = {
            'Content-Type': 'multipart/form-data',
            responseType: 'text/csv',
        };
        return axios.post('/external-recruiters/import', formData, {headers})
            .then((response) => {
                return Promise.resolve(response)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }

    }
