import axios from '@/libs/axios';

export default {
  login(email, password) {
    return axios.post('/login', {email, password});
  },
  currentUser() {
    return axios.get('/users/current')
  },
  getUserToken(uid) {
    return axios.post(`/login/users/${uid}/superadmin-authentication`)
  }
}

