import auth from './auth'
import users from './users'
import jobs from './jobs'
import stats from '@/http/stats'
import recruiters from "@/http/recruiters";

export default function useHttpService() {
  return {
    auth,
    users,
    recruiters,
    jobs,
    stats
  }
}
