import Vue from 'vue'

// axios
import axios from 'axios'
import router from '@/router'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env['VUE_APP_API_URL'],
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})


axiosIns.interceptors.request.use(request => {
  const token  = localStorage.getItem('token');
  if (token) {
    request.headers["Authorization"] = `${token}`;
  }
  return request;
})

axiosIns.interceptors.response.use((response) => response.data, onError);

async function onError(error) {
  if (error.response.status === 401) {
    localStorage.clear();
    await router.push('/login');
    return;
  }
  throw error;
}

Vue.prototype.$http = axiosIns

export default axiosIns
