<template>
  <div>
    <div>
      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>Filters</h5>
        </b-card-header>

        <b-card-body>
          <div class="row">
            <div class="col-md-2">
              <label>User type</label>
              <v-select
                  @input="$emit('update:type', $event)"
                  :reduce="val => val.value"
                  :options="userRoles">
              </v-select>
            </div>
            <div class="col-md-2">
              <label>Industry</label>
              <v-select
                  @input="$emit('update:industry', $event)"
                  :options="industryOptions"
                  label="name"
                  :reduce="val => val.uid"></v-select>
            </div>

            <div class="col-md-2">
              <label>Created</label>
              <flat-pickr
                  v-model="dateRange"
                  class="form-control"
                  :config="{ mode: 'range'}"
              />
            </div>

            <div class="col-md-2">
              <label>Views date</label>
              <flat-pickr
                  v-model="viewsRange"
                  class="form-control"
                  :config="{ mode: 'range'}"
              />
            </div>

            <div class="col-md-1 pt-2">
              <b-form-checkbox
                  id="favourite"
                  name="favourite"
                  class="checkbox"
                  @change="onFavouriteChange"
              >
                Favourites
              </b-form-checkbox>
            </div>

            <div class="col-md-1 pt-2">
              <b-form-checkbox
                  id="blocked"
                  name="blocked"
                  class="checkbox"
                  @change="onBlockedChange"
              >
                Blocked
              </b-form-checkbox>
            </div>

          </div>
        </b-card-body>

      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BFormCheckbox } from 'bootstrap-vue'
import vSelect from 'vue-select'
import usePostFilters from '@/views/Posts/usePostFilters'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    flatPickr,
    BFormCheckbox
  },
  props: {
    status: {
      type: [Boolean, null],
      default: null
    },
    type: {
      type: [String, null],
      default: null
    },
    industry: {
      type: [String, null],
      default: null
    },
    created: {
      default: null
    },
    viewsDate: {
      default: null
    },
    isBlocked: {
      default: false
    },
    isFavourite: {
      default: false
    }
  },
  data() {
    return {
      dateRange: null,
      viewsRange: null
    }
  },
  watch: {
    dateRange: {
      immediate: false,
      handler(value) {
        this.$emit('update:created', value)
      }
    },
    viewsRange: {
      immediate: false,
      handler(value) {
        this.$emit('update:viewsDate', value)
      }
    }
  },
  setup(_, {emit}) {
    const {industryOptions, userRoles} = usePostFilters()

    const onBlockedChange = (event) => {
      emit('update:isBlocked', event)
    }

    const onFavouriteChange = event => {
      emit('update:isFavourite', event)
    }

    return {
      industryOptions, userRoles, onBlockedChange, onFavouriteChange
    }
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.checkbox {
  cursor: pointer;
}
</style>
