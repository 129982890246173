<template>
  <b-card
      no-body
      class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Jobs Stats</b-card-title>
        <b-card-text class="font-small-2">
          Stats for previous day
        </b-card-text>
      </div>

    </b-card-header>

    <!-- body -->
    <b-card-body>
      <div
          class="browser-states"
      >
        <b-media class="align-items-center" no-body>
          <b-media-aside
              class="mr-2"
          >
            <b-avatar
                size="48"
                :variant="`light-secondary`"
            >
              <feather-icon
                  size="24"
                  icon="ClipboardIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              Total
            </h6>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{ jobs }}</span>
        </div>
      </div>
      <div
          v-for="(item,index) in industryStats"
          :key="item.fieldOfWork"
          class="browser-states"
      >
        <b-media class="align-items-center" no-body>
          <b-media-aside
              class="mr-2"
          >
            <b-avatar
                size="48"
                :variant="`light-${color(index)}`"
            >
              <feather-icon
                  size="24"
                  icon="ClipboardIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              {{ item.fieldOfWork }}
            </h6>
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
          <span class="font-weight-bold text-body-heading mr-1">{{ item.count }}</span>
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BAvatar, BCardBody, BMedia, BMediaAside, BMediaBody, BImg, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    BAvatar,
    VueApexCharts,
  },
  props: {
    industryStats: {
      type: Array,
      default: []
    },
    jobs: {
      type: Number,
      default: 0
    }
  },
  methods: {
    color(index) {
      if(index === 0) return 'success'
      if(index === 1) return 'warning'
      if(index === 2) return 'info'
      return 'primary'
    }
  }
}
</script>
