<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <h2>{{ editUid ? "Edit" : "Create" }} recruiter</h2>
      <b-button v-if="editUid" @click="deleteRecruiter" variant="primary"
        >Delete</b-button
      >
    </div>

    <validation-observer ref="recruitersForm" v-slot="{ invalid }">
      <div class="recruiters-form">
        <b-form @submit.prevent="save">
          <b-form-group label="Name" label-for="name">
            <validation-provider
              v-slot="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                v-model="fullName"
                :state="errors.length > 0 ? false : null"
                id="name"
                required
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Email" label-for="email">
            <validation-provider
              v-slot="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input v-model="recruiterEmail" id="email" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Phone number" label-for="phone">
            <b-form-input v-model="phoneNumber" id="phone" />
          </b-form-group>

          <b-form-group label="Company address" label-for="companyAddress">
            <b-form-input v-model="companyAddress" id="companyAddress" />
          </b-form-group>

          <b-form-group label="Country" label-for="country">
            <v-select
              v-model="countryUid"
              :options="countryOptions"
              label="name"
              :reduce="(val) => val.uid"
              id="country"
            />
          </b-form-group>

          <b-form-group label="City" label-for="city">
            <b-form-input v-model="city" id="city" />
          </b-form-group>

          <b-form-group label="Industry" label-for="industry">
            <v-select
              v-model="fieldOfWorkIds"
              :options="industryOptions"
              label="name"
              multiple
              :reduce="(val) => val.uid"
              id="industry"
            />
          </b-form-group>

          <div class="d-flex justify-content-end mt-4">
            <b-button variant="success" :disabled="invalid" type="submit">
              <b-spinner
                v-if="loading"
                small
                style="margin-bottom: 2px;"
              ></b-spinner>
              Save
            </b-button>
          </div>
        </b-form>
      </div>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormFile,
  BButton,
  BSpinner,
  BForm,
  BFormGroup,
  BCardTitle,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import { VueEditor } from "vue2-editor";
import vSelect from "vue-select";
import http from "@/http/recruiters";
import { toastMixin } from "@/mixins/toast-mixin";
import useUserFilters from "@/components/users/useUserFilters";
import VuexyLogo from "@core/layouts/components/Logo";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@core/utils/validations/validations";

export default {
  mixins: [toastMixin],
  components: {
    BCard,
    BFormInput,
    VueEditor,
    vSelect,
    BFormFile,
    BButton,
    BSpinner,
    BForm,
    BFormGroup,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      fieldOfWorkIds: [],
      countryUid: "",
      editUid: null,
      fullName: "",
      phoneNumber: "",
      recruiterEmail: "",
      companyAddress: "",
      city: "",
      loading: false,
      countryId: null,
      id: "",
      fieldsOfWork: [],
      // validation rules
      required,
      email,
    };
  },

  created() {
    const { id } = this.$route.params;
    if (id !== "create") {
      http.getRecruiter(id).then((response) => {
        this.fullName = response.fullName;
        this.recruiterEmail = response.email;
        this.companyAddress = response.companyAddress;
        this.city = response.city;
        this.editUid = response.uid;
        this.countryUid = response.countryUid;
        this.fieldOfWorkIds = response.fieldOfWorkIds;
        this.id = response.id;
        this.phoneNumber = response.phoneNumber;
      });
    }
  },

  setup() {
    const { industryOptions, countryOptions } = useUserFilters(true);

    return {
      industryOptions,
      countryOptions,
    };
  },

  methods: {
    createFormData() {
      return {
        email: this.recruiterEmail,
        fullName: this.fullName,
        companyAddress: this.companyAddress,
        city: this.city,
        countryUid: this.countryUid,
        titleName: this.titleName,
        fieldOfWorkIds: this.fieldOfWorkIds,
        phoneNumber: this.phoneNumber,
      };
    },

    async save() {
      try {
        let body = this.createFormData();

        if (this.editUid) {
          body = {
            ...body,
            id: this.id,
            uid: this.editUid,
          };
        }

        await http.saveRecruiter(body);

        this.showSuccess("Success", "Saved");
        this.$router.push("/external-recruiters/");
      } catch (e) {
        if (!e)
          this.showToast(
            "error",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong"
          );

        this.showToast(
          "error",
          "AlertTriangleIcon",
          "Error",
          e.errors.CreateExternalRecruiter[0]
        );
      } finally {
        this.loading = false;
      }
    },
    deleteRecruiter() {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete recruiter?`, {
          title: "Delete recruiter",
          centered: true,
          cancelVariant: "outline-secondary",
        })
        .then((value) => {
          if (value) {
            http.deleteRecruiter(this.editUid).then(() => {
              this.showSuccess("Success", "Successfully deleted");
              this.$router.push("/external-recruiters");
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.recruiters-form {
  display: grid;
  gap: 10px;
}
</style>
