<template>
  <div class="post-images">

    <FsLightbox
      :exitFullscreenOnClose="true"
      :toggler="showLightbox"
      :sources="images"
      :slide="slide"
      :types="imagesTypes"
    />

    <div class="images-grid" :class="`images-grid-${imagesShortList.length}`">
      <div v-for="(image, index) in imagesShortList" class="image" :key="index" @click="openLightbox(index + 1)">
        <img :src="image" alt="">
        <div v-if="index === 2 && images.length > 3" class="more-overlay">
          view all {{ images.length }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FsLightbox from "fslightbox-vue";

export default {
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  components: {FsLightbox},
  data() {
    return {
      showLightbox: false,
      slide: 1
    }
  },
  computed: {
    imagesShortList() {
      return this.images.slice(0, 3)
    },
    imagesTypes() {
      return new Array(this.images.length).fill('image')
    },

  },
  methods: {
    openLightbox(slide) {
      this.slide = slide;
      this.showLightbox = !this.showLightbox;
    }
  }
}
</script>

<style lang="scss" scoped>
.post-images {
  .images-grid {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;

    .image:first-child {
      grid-row: span 2
    }

    .image {
      position: relative;
    }

    .image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }

    .more-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #0000007a;
      z-index: 100;

      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #ffffff;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .images-grid-1 {
    .image {
      background: black;
      img {
        max-height: 500px;
        object-fit: contain;
      }
    }

    @media (max-width: 600px) {
      .image img {
        max-height: 400px;
      }
    }
  }

  .images-grid-2 {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .images-grid-3 {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 135px;
  }
}
</style>

