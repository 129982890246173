<template>
  <div>
    <div>
      <b-card no-body>
        <b-card-header class="pb-50">
          <h5>Filters</h5>
        </b-card-header>

        <b-card-body>
          <div class="row">
            <div class="col-md-2">
              <label>Position</label>
              <v-select
                  @input="$emit('update:position', $event)"
                  :options="positions"
                  ></v-select>
            </div>

            <div class="col-md-2">
              <label>Date</label>
              <flat-pickr
                  v-model="dateRange"
                  class="form-control"
                  :config="{ mode: 'range'}"
              />
            </div>

            <div class="col-md-2">
              <label>Views date</label>
              <flat-pickr
                  v-model="viewsRange"
                  class="form-control"
                  :config="{ mode: 'range'}"
              />
            </div>


          </div>
        </b-card-body>

      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BCardBody, BCardHeader, BFormCheckbox } from 'bootstrap-vue'
import vSelect from 'vue-select'
import usePostFilters from '@/views/Posts/usePostFilters'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    flatPickr,
    BFormCheckbox
  },
  props: {
    status: {
      type: [Boolean, null],
      default: null
    },
    type: {
      type: [String, null],
      default: null
    },
    industry: {
      type: [String, null],
      default: null
    },
    position: {
      type: [Number, null],
      default: null
    },
    viewsDate: {
      default: null
    },
    sponsoredDate: {
      default: null
    },
    created: {
      default: null
    },
    isBlocked: {
      default: false
    },
    isFavourite: {
      default: false
    }
  },
  data() {
    return {
      dateRange: null,
      viewsRange: null,
      positions: []
    }
  },
  watch: {
    dateRange: {
      immediate: false,
      handler(value) {
        this.$emit('update:sponsoredDate', value)
      }
    },
    viewsRange: {
      immediate: false,
      handler(value) {
        this.$emit('update:viewsDate', value)
      }
    }
  },
  setup(_, {emit}) {
    const {industryOptions, userRoles} = usePostFilters()

    const onBlockedChange = (event) => {
      emit('update:isBlocked', event)
    }

    const onFavouriteChange = event => {
      emit('update:isFavourite', event)
    }

    return {
      industryOptions, userRoles, onBlockedChange, onFavouriteChange
    }
  },
  created() {
    this.$httpService.posts.getSponsoredPositions().then(res => {
      this.positions = res
    })
  }
}
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.checkbox {
  cursor: pointer;
}
</style>
