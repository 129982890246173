<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <h2>{{ editUid ? 'Edit' : 'Create' }} blog</h2>
      <b-button v-if="editUid" @click="deleteBlog" variant="primary">Delete</b-button>
    </div>
    <div class="blog-form">
      <div>
        <label>Type</label>
        <v-select :clearable="false" v-model="form.type" :options="['OUR', 'INDUSTRY']"></v-select>
      </div>
      <div>
        <label>Image</label>
        <b-form-file accept="image/*" @change="onImageUpload" :placeholder="editUid ? 'Choose new image' : 'No file chosen'"></b-form-file>
        <div class="mt-2" v-if="imagePreview">
          <img :src="imagePreview" style="max-width: 100%" height="500" alt="">
        </div>
      </div>
      <div>
        <label>Title</label>
        <b-form-input v-model="form.title"/>
      </div>
      <div>
        <label>Text</label>
        <vue-editor
            @image-removed="onImageRemoved"
            @image-added="handleImageAdded"
            :useCustomImageHandler="true"
            :customModules="customModulesForEditor"
            :editorOptions="editorSettings"
            :editorToolbar="customToolbar"
            v-model="form.content"
        ></vue-editor>
      </div>
      <!--      <div>-->
      <!--        <label>Credits</label>-->
      <!--        <b-form-input v-model="form.credits"/>-->
      <!--      </div>-->
    </div>
    <div class="d-flex justify-content-end mt-4">
      <b-button variant="success" @click="save" :disabled="loading">
        <b-spinner v-if="loading" small style="margin-bottom: 2px;"></b-spinner>
        Save
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BFormInput, BFormFile, BButton, BSpinner } from 'bootstrap-vue'
import { VueEditor } from 'vue2-editor'
import vSelect from 'vue-select'
import http from '@/http/blogs'
import { toastMixin } from '@/mixins/toast-mixin'
import ImageResize from 'quill-image-resize-vue'

export default {
  mixins: [toastMixin],
  components: {
    BCard,
    BFormInput,
    VueEditor,
    vSelect,
    BFormFile,
    BButton,
    BSpinner
  },
  data() {
    return {
      form: {
        content: '',
        type: 'OUR',
        image: null,
        credits: '',
        title: ''
      },
      editTitle: null,
      editUid: null,
      loading: false,
      imagePreview: null,
      content: '',
      customModulesForEditor: [
        {
          alias: 'imageResize',
          module: ImageResize
        }
      ],
      editorSettings: {
        modules: {
          imageResize: {}
        }
      },
      customToolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'align': [] }],
        ['image', 'video','link']// outdent/indent

      ]
    }
  },
  methods: {
    async onImageRemoved(image) {
      await http.deleteImage(image)
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      this.showSuccess('Image uploading', 'Please wait')
      http.uploadImage(file).then(response => {
        Editor.insertEmbed(cursorLocation, "image", response);
        resetUploader();
      })
    },
    onImageUpload(event) {
      const selectedFile = event.target.files[0]
      this.form.image = selectedFile
      const reader = new FileReader()

      reader.onload = (event) => {
        this.imagePreview = event.target.result
      }

      reader.readAsDataURL(selectedFile)
    },
    async save() {
      if(!this.form.title || !this.form.content || !this.form.image) {
        this.showError("Title, image and content are required")
        return
      }
      this.loading = true

      try {
        if (this.editUid) {
          await http.updateBlog(this.editUid, this.form)
        } else {
          await http.createBlog(this.form)
        }
        this.showSuccess('Success', 'Saved')
        this.$router.push('/blogs/')
      } catch (e) {
        this.showToast('error', 'AlertTriangleIcon', 'Error', 'Something went wrong!')
      } finally {
        this.loading = false
      }
    },
    deleteBlog() {
      this.$bvModal
          .msgBoxConfirm(`Please confirm that you want to delete blog?`, {
            title: 'Delete blog',
            centered: true,
            cancelVariant: 'outline-secondary',
          })
          .then(value => {
            if (value) {
              http.deleteBlog(this.editUid)
                  .then(() => {
                    this.$router.push('/blogs')
                  })
            }
          })
    }
  },
  created() {
    const { id } = this.$route.params
    if (id !== 'create') {
      http.getBlog(id)
          .then(response => {
            this.editTitle = response.title
            this.form.image = response.image
            this.editUid = response.uid
            this.form.title = response.title
            this.imagePreview = response.image
            this.form.content = response.content
          })
    }

  }

}
</script>

<style scoped>
.blog-form {
  display: grid;
  gap: 10px;
}
</style>
