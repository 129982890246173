export const resolveUserRoleVariant = role => {
  if (role === 'Crew') return 'success'
  if (role === 'Recruiter') return 'warning'
  return 'primary'
}

export const resolveBooleanIcon = value => {
  if (value) return 'CheckIcon';
  else return 'XIcon';
}
