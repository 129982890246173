<template>
  <div>
    <div class="d-flex justify-content-end">
      <div class="close-icon" @click="$emit('closeModal')">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path fill-rule="evenodd"
                d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"/>
        </svg>
      </div>
    </div>
    <div>
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>Show</label>
          <v-select
              v-model="pageSize"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>
      </b-row>
    </div>
    <div class="user-search">
      <input v-model="search" class="user-search__size" type="text" placeholder="Search by name...">
    </div>
    <b-table
        class="created-accounts-table"
        ref="refTable"
        :items="createdUsers"
        responsive
        :fields="createdUsersModalTable"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">
      <template #cell(user)="data">
        <div class="d-flex">
          <b-media vertical-align="center">
            <b-link
                v-if="data.item.name !== 'Someone'"
                target="_blank"
                class="font-weight-bold d-block text-nowrap"
                :to="resolveUserLink(data.item.type, data.item.userUid)">
              {{ data.item.name }}
            </b-link>
            <b-button :id="data.item.userUid" v-else class="btn--no-name">
              {{ data.item.name }}
            </b-button>
            <b-tooltip :target="data.item.userUid" title="Tooltip title" triggers="hover">
              This profile is not created yet.
            </b-tooltip>
          </b-media>
        </div>
      </template>

      <template #cell(status)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.status }}</span>
        </div>
      </template>

      <template #cell(actions)="data">
        <b-dropdown variant="link" class="options-dropdown">
          <template #button-content>
            <b-button variant="success" class="btn--edit-status" size="sm">Edit status</b-button>
          </template>

          <b-dropdown-item @click="changeStatus(data.item.referalUsersUid, 'CONFIRMED')">
            <span class="align-middle">CONFIRMED</span>
          </b-dropdown-item>


          <b-dropdown-item @click="changeStatus(data.item.referalUsersUid, 'INVALID')">
            <span class="align-middle">INVALID</span>
          </b-dropdown-item>


          <b-dropdown-item @click="changeStatus(data.item.referalUsersUid, 'CREATED')">
            <span class="align-middle">CREATED</span>
          </b-dropdown-item>


          <b-dropdown-item @click="changeStatus(data.item.referalUsersUid, 'VERIFIED')">
            <span class="align-middle">VERIFIED</span>
          </b-dropdown-item>

        </b-dropdown>
      </template>
    </b-table>
    <b-col class="d-flex align-items-center justify-content-end">
      <b-pagination
          v-model="page"
          :total-rows="totalCount"
          :per-page="pageSize"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item">
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18"/>
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18"/>
        </template>
      </b-pagination>
    </b-col>
  </div>
</template>

<script>

import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BTooltip,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import {avatarText} from '@core/utils/filter'
import useUsersList from './useUsersList'
import UsersFilters from '@/components/users/UsersFilters'


export default {
  name: "CreatedUsers",
  components: {
    UsersFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BTooltip,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      createdUsers: [],
      search: '',
      page: 1,
      pageSize: 10,
      createdUsersModalTable: [
        {
          key: 'user',
          thStyle: {width: "20%"}
        },
        {
          key: 'status',
          thStyle: {width: "20%"}
        },
        {
          key: 'actions',
          thStyle: {width: "5%"}
        }
      ]
    }
  },
  watch: {
    search() {
      this.page = 1
      this.fetchCreatedUsers();
    },
    pageSize() {
      this.fetchCreatedUsers();
    },
    page() {
      this.fetchCreatedUsers();
    }
  },
  props: ['id'],
  setup() {
    const usersList = useUsersList(false, false)
    return {
      avatarText,
      ...usersList,
    }
  },
  mounted() {
    this.fetchCreatedUsers();
  },
  methods: {
    async fetchCreatedUsers() {
      const data = await this.$httpService.users.getCreatedUsers(this.page, this.search, this.pageSize, this.id);
      this.createdUsers = data.results;
      this.totalCount = data.totalCount;
    },
    async changeStatus(id, status) {
      await this.$httpService.users.changeUserStatus(id, status);
      this.fetchCreatedUsers();
    }
  }
}
</script>

<style lang="scss" scoped>

.created-accounts-table {
  max-height: 550px;
  overflow: scroll;
}

.btn--edit-status {
  padding: 10px 5px;
}

.options-dropdown {
  position: static;
}

.table td {
  padding: 0 !important;
}

.user-search {
  padding: 20px 0;

  &__size {
    width: 100%;
    height: 38px;
  }
}

.col-width {
  width: 40px;
}

.close-icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

svg {
  fill: #6e6b7b;
}

.btn--no-name {
  padding: 0;
  border: none;
  background-color: white !important;
  color: var(--color-primary) !important;
}

</style>