<template>
  <div>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Filters</h5>
      </b-card-header>

      <b-card-body>
        <div class="row">
          <div class="col-md-2" v-if="!hideFilters.includes('type')">
            <label>Type</label>
            <v-select
              @input="$emit('update:type', $event)"
              :reduce="(val) => val.value"
              :options="typeOptions"
            >
            </v-select>
          </div>
          <div class="col-md-2" v-if="!hideFilters.includes('industry')">
            <label>Industry</label>
            <v-select
              @input="$emit('update:industry', $event)"
              :options="industryOptions"
              label="name"
              multiple
              :reduce="(val) => val.uid"
            ></v-select>
          </div>
          <div class="col-md-2" v-if="!hideFilters.includes('visible')">
            <label>Visible</label>
            <v-select
              @input="$emit('update:status', $event)"
              :reduce="(val) => val.value"
              :options="statusOptions"
            >
            </v-select>
          </div>
          <div
            class="col-md-2"
            v-if="!hideFilters.includes('activeSubscription')"
          >
            <label>Active subscription</label>
            <v-select></v-select>
          </div>
          <div class="col-md-2" v-if="!hideFilters.includes('companyType')">
            <label>Company type</label>
            <v-select
              :reduce="(val) => val.value"
              @input="$emit('update:companyType', $event)"
              :options="companyTypeOptions"
            >
            </v-select>
          </div>
          <div class="col-md-2" v-if="!hideFilters.includes('receivingEmails')">
            <label>Recieving Emails</label>
            <v-select
              :reduce="(val) => val.value"
              @input="$emit('update:isSendMyCvAllowed', $event)"
              :options="sendMyCvAllowedOptions"
            >
            </v-select>
          </div>
          <div class="col-md-2" v-if="!hideFilters.includes('created')">
            <label>Created</label>
            <flat-pickr
              v-model="dateRange"
              class="form-control"
              :config="{ mode: 'range' }"
            />
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-md-2" v-if="!hideFilters.includes('country')">
            <label>Country</label>
            <v-select
              @input="$emit('update:country', $event)"
              :options="countryOptions"
              label="name"
              :reduce="(val) => val.uid"
            ></v-select>
          </div>
          <div class="col-md-2" v-if="!hideFilters.includes('verified')">
            <label>Verified</label>
            <v-select
              @input="onVerifiedChange"
              :options="verifiedOptions"
              :reduce="(val) => val.value"
            ></v-select>
          </div>
          <div class="col-md-2" v-if="!hideFilters.includes('viewsDate')">
            <label>Views date</label>
            <flat-pickr
              v-model="viewsRange"
              class="form-control"
              :config="{ mode: 'range' }"
            />
          </div>
          <div
            class="col-md-6 mt-2 d-flex flex-wrap cursor-pointer"
            style="gap: 10px"
          >
            <b-form-checkbox
              id="isInactive"
              name="isInactive"
              class="checkbox mr-2"
              @change="$emit('update:isInactive', $event)"
              v-if="!hideFilters.includes('inactive')"
            >
              Inactive
            </b-form-checkbox>
            <b-form-checkbox
              id="completed"
              name="completed"
              class="checkbox mr-2"
              @change="onCompletedChange"
              v-if="!hideFilters.includes('incomplete')"
            >
              Incomplete
            </b-form-checkbox>
            <b-form-checkbox
              id="premium"
              name="premium"
              class="checkbox mr-2"
              @change="onPremiumChange"
              v-if="!hideFilters.includes('premium')"
            >
              Premium
            </b-form-checkbox>
            <!--            <b-form-checkbox-->
            <!--                id="notVerified"-->
            <!--                name="notVerified"-->
            <!--                class="checkbox"-->
            <!--                @change="onVerifiedChange"-->
            <!--            >-->
            <!--              Is not verified-->
            <!--            </b-form-checkbox>-->
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BFormCheckbox } from "bootstrap-vue";
import vSelect from "vue-select";
import useUserFilters from "@/components/users/useUserFilters";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    flatPickr,
    BFormCheckbox,
  },
  props: {
    status: {
      type: [Boolean, null],
      default: null,
    },
    type: {
      type: [String, null],
      default: null,
    },
    companyType: {
      type: [String],
      default: "",
    },
    industry: {
      type: [Array, null],
      default: null,
    },
    created: {
      default: null,
    },
    country: {
      default: null,
    },
    isIncomplete: {
      default: false,
    },
    isInactive: {
      default: false,
    },
    isPremium: {
      default: false,
    },
    isSendMyCvAllowed: {
      type: [String],
      default: null,
    },
    isNotVerified: {
      default: false,
    },
    hideFilters: {
      default: [],
    },
  },
  data() {
    return {
      dateRange: null,
      viewsRange: null,
    };
  },
  watch: {
    dateRange: {
      immediate: false,
      handler(value) {
        this.$emit("update:created", value);
      },
    },
    viewsRange: {
      immediate: false,
      handler(value) {
        this.$emit("update:viewsDate", value);
      },
    },
  },
  setup(_, { emit }) {
    const {
      statusOptions,
      typeOptions,
      industryOptions,
      countryOptions,
      companyTypeOptions,
      verifiedOptions,
      sendMyCvAllowedOptions,
    } = useUserFilters();

    const onCompletedChange = (event) => {
      emit("update:isIncomplete", event);
    };

    const onPremiumChange = (event) => {
      emit("update:isPremium", event);
    };

    const onVerifiedChange = (event) => {
      emit("update:isNotVerified", event);
    };

    return {
      statusOptions,
      typeOptions,
      industryOptions,
      countryOptions,
      onCompletedChange,
      companyTypeOptions,
      sendMyCvAllowedOptions,
      onPremiumChange,
      onVerifiedChange,
      verifiedOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
