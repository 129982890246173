import axios from '@/libs/axios';

export default {
  getJobs(page = 1, pageSize = 10, search = null, status = null, filters) {
    return axios.get('/jobs/administration', {params: {page, pageSize, search, statuses: status, ...filters}});
  },
  verifyJob(uid) {
    return axios.patch(`/jobs/${uid}/verify`)
  },
  exportJobsToExel(page = 1, pageSize = 10, search = null, status = null, filters) {
    return axios.get('/jobs/administration/export', {responseType: 'blob', params: {page, pageSize, search, statuses: status, ...filters}});
  },
  deleteJob(uid) {
    return axios.delete(`/jobs/${uid}`)
  },
  getFilters() {
    return axios.get('/jobs/administration/filters')
  }
}
